import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'font-awesome/css/font-awesome.min.css';
import Home from "./pages/Home";
import Seo from "./pages/seo";
import Requestquote from "./pages/requestquote";

import Blog from "./pages/blog";
import Blogdetail from "./pages/blogdetail";
import Blogcategory from "./pages/blogcategory";
import Sitemap from './pages/sitemap'
import PPC from "./pages/ppc";

import ScrollToTop from '../src/components/scrolltop'
// import { RemoveTrailingSlash } from "./pages/redirection";
import Errorpage from "./pages/404error";



export default function App({ ...rest }) {

  //   console.log = function () { };

  // console.log = () => { };
  console.debug = () => { };
  console.info = () => { };
  console.warn = () => { };
  const pathname = window.location.pathname;
  console.log("pathnamepathname", pathname, pathname.includes('search'))
  const FetchUrl = window.location.search
  console.log("FetchUrldata", FetchUrl);
  const DecodeUrl = decodeURIComponent(FetchUrl)
  console.log("DecodeUrl1111", DecodeUrl);
  const SplitData = DecodeUrl.split('&oq=cache')[0]
  console.log("SplitData", SplitData);
  const parts = SplitData.split('https://nounq.com');
  console.log("partsparts", parts);
  const lastPart = parts[parts.length - 1] ? parts[parts.length - 1] : '/';
  console.log("DecodeUrlDecodeUrl", lastPart);
  const queryString = lastPart


  const decideRoute = () => {
    switch (queryString) {
      case '/': return <Home />; break;
      case '/seo-services': return <Seo />; break;
      case '/ppc-services': return <PPC />; break;
      case '/requestquote': return <Requestquote />; break;
      case '/errorpage': return <Errorpage />; break;
      case '/blog': return <Blog />; break;
      case '/:slug': return <Blogdetail />; break;
      case '/blogcategory/:slug': return <Blogcategory />; break;

      default: return <Home />
    }
  }


  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      {/* <RemoveTrailingSlash/> */}
      <Routes>

        <Route exact path="/" element={<Home />} />
        <Route path="/seo-services" element={<Seo />} />
        <Route path="/ppc-services" element={<PPC />} />
        <Route path='/requestquote' element={<Requestquote />} />
        <Route path="/errorpage" element={<Errorpage />} />
        <Route path='/blog' element={<Blog />} />
        {/* {pathname != '/search' ? */}
        <Route path='/:slug' element={<Blogdetail />} /> 
        {/* : <></> */}
        {/* } */}
        <Route path='/blogcategory/:slug' element={<Blogcategory />} />
        {pathname.includes('search') ?
          <Route exact path="/*" element={
            decideRoute()
          } />
          :
          <Route
            path="/*"
            element={<Navigate to="/errorpage" replace />}
          />
        }

      </Routes>
    </BrowserRouter>
  );
}

